import * as ImagesLoaded from '../../../../vendor/gray_matter/base/app/assets/javascript/utility/images_loaded.js'

export var projects_index;
var mobile_filter_toggle;
var clear_filter;
var project_info_toggle_container;

export async function init(){
  projects_index = document.getElementById('projects_index');
  mobile_filter_toggle = document.getElementById('mobile_filter_toggle');
  clear_filter = document.getElementById('clear_filter');
  project_info_toggle_container = document.getElementById('project_info_toggle_container');

  document.querySelectorAll('.filter_button').forEach((e) => e.addEventListener('click', filterProjects));

  if (clear_filter) clear_filter.addEventListener('click', resetFilters);
  if (mobile_filter_toggle) mobile_filter_toggle.addEventListener('click', toggleFilters);
  if (project_info_toggle_container){ 
    project_info_toggle_container.addEventListener('click', toggleProjectInfo);
    document.getElementById('page_details_sizer').addEventListener('transitionend', infoTransitionEnd);
  }
  if (projects_index){
    await ImagesLoaded.waitForAll(projects_index);
  }
}

function filterProjects(){
  document.body.classList.add('with_filters');
  document.querySelectorAll('.filter_container').forEach((e) => e.classList.remove('selected'));
  this.closest('.filter_container').classList.add('selected');

  var filtered = this.dataset.name;
  if (!filtered){
    Turbolinks.visit('/projects');
    return;
  }
  Turbolinks.visit(`/projects/category/${filtered}`);
}

function resetFilters(){
  Turbolinks.visit('/projects');
}

function toggleFilters(){
  mobile_filter_toggle.classList.toggle('show');
  document.getElementById('filter_container').classList.toggle('show');
}

function toggleProjectInfo(){
  var summary = document.getElementById('page_summary');
  var sizer = document.getElementById('page_details_sizer');
  sizer.style.height = sizer.getBoundingClientRect().height + 'px';
  sizer.offsetHeight;
  if (summary.classList.contains('open')){
    sizer.style.height = '0px';
  } else {
    sizer.style.height = document.getElementById('page_details_wrap').getBoundingClientRect().height + 'px';
  }
}

function infoTransitionEnd(e){
  document.getElementById('page_details_sizer').style.height = '';
  document.getElementById('page_summary').classList.toggle('open');
}
